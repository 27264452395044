import _ from 'lodash';
import WebAppApi from '../api/webapp';
import {
  ADD_TOAST,
  CHANGE_REGIONAL_PREF,
  CHANGE_ROLE,
  CHANGE_USER_INSTITUTION,
  ENHANCE_HEALTH_SPACE_DATA,
  FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES,
  FETCH_PATIENT_DATA,
  FETCH_PATIENT_LAST_ASSESSMENT,
  FETCH_PATIENT_TIMELINE_ASSESSMENTS,
  FETCH_PATIENT_TODO_ASSESSMENTS,
  FETCH_PROFESSIONAL_DATA,
  FETCH_PROFESSIONAL_DISEASES,
  FETCH_PROFESSIONAL_INSTITUTIONS,
  FETCH_USER_DATA,
  MEASUREMENTS_JWT,
  RESET_NOTIFICATIONS,
  SET_HEALTH_SPACE,
  SET_PATIENT_INSTITUTIONS,
  USER_PHONE_VALIDATED,
  USER_UPDATE_DATA,
  USER_UPDATE_DATA_BEGINS,
  USER_UPDATE_DATA_ERROR,
  USER_UPDATE_DATA_SIGNUP_DETAILS
} from '../constants/actionTypes';
import {
  PHONE_ACCOUNT_VALIDATION_SUCCESS,
  RESEND_EMAIL_ACCOUNT_VALIDATION_SUCCESS,
  RESEND_PHONE_SMS_ACCOUNT_VALIDATION_SUCCESS,
  SERVER_ACTION_SUCCESS_UPDATE,
  SERVER_ERROR,
  VALIDATION_ACCOUNT_SUCCESS
} from '../constants/messages';
import { USER_URL } from '../constants/urls';
import axios from '../utils/axios';
import { fetchClinicalTrialsBaseAction } from './settings';

/**
 * Action responsable for get user information.
 * - Gets information about role (patient or professiona)
 * - Gets Measurements JWT
 * - Get Language and Regional preferences
 *
 * @returns {Promise} - Returns new promise
 */
export const fetchUserData = () => dispatch =>
  axios
    .get(USER_URL)
    .then(response => {
      dispatch({
        type: FETCH_USER_DATA,
        payload: {
          ...response.data.user,
          notifications: response.data.notifications,
          role: Object.keys(response.data.professional || {}).length ? 'professional' : 'patient',
          health_spaces: [...response.data.user.health_spaces],
          helpscout: response.data.help_scout,
          has_used_mobile: response.data.has_used_mobile
        }
      });

      dispatch({
        type: FETCH_PROFESSIONAL_DATA,
        payload: response.data.professional || {}
      });

      const { language, timezone } = response.data.user.preferences.regional;

      try {
        window.i18next.changeLanguage(language);
        window.localStorage.setItem('lang', language);
        // in iiag for example, we want to change the preferences of the eprom to have a certain language but then when all is finished we want to return to the professional original lang.
        sessionStorage.setItem('originalLang', language);
      } catch (e) {
        console.log(e);
      }

      dispatch({
        type: CHANGE_REGIONAL_PREF,
        payload: {
          language,
          timeZone: timezone
        }
      });

      return response;
    })
    .catch(error => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
    });

export const fetchProfessionalDetails = URL => dispatch =>
  axios
    .get(URL)
    .then(response => {
      dispatch({
        type: FETCH_PROFESSIONAL_DATA,
        payload: response.data
      });
      return response;
    })
    .catch(response => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
    });

/**
 * Make patch request to update professional information
 * @param {object} user
 * @param {object} data
 *
 * @returns {Promise}
 */

export const updateProfessionalData = (user, data) => dispatch =>
  axios
    .patch(`/users/${user.uuid}/professional`, data)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ACTION_SUCCESS_UPDATE
      });
      dispatch({
        type: USER_UPDATE_DATA,
        payload: response.data
      });
      dispatch({
        type: USER_UPDATE_DATA_ERROR,
        payload: {}
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      if (error.response.status === 400) {
        dispatch({
          type: USER_UPDATE_DATA_ERROR,
          payload: error.response.data || {}
        });
      } else {
        dispatch({
          type: USER_UPDATE_DATA_ERROR,
          payload: {}
        });
      }
      return error.response;
    });

export const userUpdateBegins = () => ({
  type: USER_UPDATE_DATA_BEGINS
});

export const userResetErrors = () => dispatch =>
  dispatch({
    type: USER_UPDATE_DATA_ERROR,
    payload: {}
  });

/**
 * Updates user private data (Name, gender, phone, email)
 * @param {string} URL
 * @param {object} user
 *
 * @return {Promise}
 */

export const updateUserData = (URL, user) => dispatch => {
  dispatch(userUpdateBegins());
  return axios
    .patch(URL, user)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ACTION_SUCCESS_UPDATE
      });
      dispatch({
        type: USER_UPDATE_DATA,
        payload: response.data
      });
      return response;
    })
    .catch(error => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      if (error.response.status === 400) {
        dispatch({
          type: USER_UPDATE_DATA_ERROR,
          payload: error.response.data || {}
        });
      } else {
        dispatch({
          type: USER_UPDATE_DATA_ERROR,
          payload: {}
        });
      }
      return error.response;
    });
};

export const updateUserDataSignUpDetail = (URL, user) => dispatch =>
  axios
    .patch(URL, user)
    .then(response => {
      dispatch({
        type: USER_UPDATE_DATA_SIGNUP_DETAILS,
        payload: response.data
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
    });

export const changeUserRole = role => ({
  type: CHANGE_ROLE,
  payload: role
});

export const fetchProfessionalInstitutions = () => ({
  type: FETCH_PROFESSIONAL_INSTITUTIONS,
  payload: undefined
});

/**
 * Allow professionals to change there selected institution
 * @param {string} institutionID
 */

export const changeSelectedInstitution = institutionID => ({
  type: CHANGE_USER_INSTITUTION,
  payload: institutionID
});

export const sendValidationEmailPassword = data => dispatch =>
  axios
    .post(`/account/confirm/email/${data.uid}/${data.token}`, data)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: VALIDATION_ACCOUNT_SUCCESS
      });
      return response;
    })
    .catch(request => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return request.response;
    });

export const resendExpiredEmail = email => dispatch =>
  axios
    .post('/account/expired-link', { email })
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: RESEND_EMAIL_ACCOUNT_VALIDATION_SUCCESS
      });
      return response;
    })
    .catch(request => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return request.response;
    });

export const resendPhoneSMS = () => dispatch =>
  WebAppApi.request({ method: 'get', url: '/verification/resend/phone' })(dispatch)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: RESEND_PHONE_SMS_ACCOUNT_VALIDATION_SUCCESS
      });
      return response;
    })
    .catch(request => request.response);

export const resendEmail = () => dispatch =>
  WebAppApi.request({ method: 'get', url: '/verification/resend/email' })(dispatch)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: RESEND_EMAIL_ACCOUNT_VALIDATION_SUCCESS
      });
      return response;
    })
    .catch(request => request.response);

export const validateSMSCode = (code, phone) => dispatch =>
  WebAppApi.request({ method: 'post', url: '/account/confirm/phone', data: { code, phone } })(dispatch)
    .then(response => {
      dispatch({
        type: ADD_TOAST,
        payload: PHONE_ACCOUNT_VALIDATION_SUCCESS
      });

      dispatch({
        type: USER_PHONE_VALIDATED,
        payload: { phone }
      });
      return response;
    })
    .catch(request => request.response);

export const resetNotifications = () => dispatch => {
  dispatch({
    type: RESET_NOTIFICATIONS
  });
};

export const fetchProfessionalDiseases = URL => dispatch =>
  axios.get(URL).then(response => {
    dispatch({
      type: FETCH_PROFESSIONAL_DISEASES,
      payload: response.data
    });
  });

/**
 * Get user information from health provider. Patient data and institutions are retrived.
 * @param {object} healthSpace
 */
export const fetchHealthSpaceUserData = healthSpace => async dispatch => {
  const {
    data: { patient }
  } = await WebAppApi.fetchHealthSpaceMe(healthSpace);

  if (patient) {
    const {
      data: { data: institutions }
    } = await WebAppApi.fetchHealthSpaceInstitutions(healthSpace, patient);

    const userData = {
      healthSpace,
      patient,
      institutions
    };

    dispatch({
      type: ENHANCE_HEALTH_SPACE_DATA,
      payload: userData
    });

    return userData;
  }
};

export const fetchAllUserDataFromHealthSpaces = healthSpaces => async dispatch => {
  const res = await Promise.all(healthSpaces.map(hs => fetchHealthSpaceUserData(hs)(dispatch)));
  dispatch({
    type: FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES,
    payload: res
  });
};

export const fetchClinicalTrialsFromHealthSpaces = healthSpaces => async dispatch => {
  const requests = healthSpaces
    .filter(hs => hs.patient)
    .map(hs =>
      axios
        .get(`${hs.url}/api/terms/patients/${hs.patient.id}/clinical-trials`)
        .then(res => res.data.map(ct => ({ ...ct, hsUrl: hs.url })))
    );

  const responses = _.flatMap(await Promise.all(requests));
  dispatch(fetchClinicalTrialsBaseAction(responses));
};

/**
 * Set selected health space and current patient
 * @param {object} healthSpace
 */

export const setHealthSpace = (healthSpace, institutionId = null) => dispatch => {
  // update selected patient
  dispatch({
    type: FETCH_PATIENT_DATA,
    payload: healthSpace.patient
  });

  // set institutions that belongs to patient in health space
  dispatch({
    type: SET_PATIENT_INSTITUTIONS,
    payload: {
      institutions: healthSpace.institutions,
      selected: healthSpace.institutions.find(i => i.id === institutionId) || healthSpace.institutions[0]
    }
  });

  // set current measurament url
  const { measurements } = healthSpace.patient || {};
  dispatch({
    type: MEASUREMENTS_JWT,
    payload: {
      url: measurements ? measurements.url : undefined,
      jwt: measurements ? measurements.jwt : undefined,
      renew: measurements ? measurements.renew_jwt_url : undefined
    }
  });

  // select currrent health space
  dispatch({
    type: SET_HEALTH_SPACE,
    payload: healthSpace
  });
};

export const getLoginSource = () => dispatch => {
  axios
    .get(USER_URL)
    .then(response => (response.data.user ? response.data.user.login_source : 'local'))
    .catch(request => {
      // dispach error toast
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return request.response;
    });
};

export const fetchPatientPreferences = patientUrl => dispatch =>
  axios
    .get(`${patientUrl}/preferences`)
    .then(response => response)
    .catch(request => {
      // dispach error toast
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return request.response;
    });

/**
 * Request assessments to fill for specific patient
 * @param {object} patientID
 * @param {string} filters optional, used for filter api
 */
export const fetchPatientAssessments = (patientID, filters = '') => dispatch =>
  WebAppApi.getTodoAssessments(patientID, filters).then(response => {
    dispatch({
      type: FETCH_PATIENT_TIMELINE_ASSESSMENTS,
      payload: {
        timelineAssessments: response.data
      }
    });
    return response;
  });

/**
 * Fetch patient last assessment
 * @param {object} patientID
 * @param {number} patientDiseaseId
 * @param {string} type type of the assessment we want to fetch, by default being crom. Can be crom/prom/prem
 */
export const fetchPatientLastAssessment = (patientID, patientDiseaseId, type = 'crom') => dispatch =>
  WebAppApi.getPatientLastAssessment(patientID, patientDiseaseId, type).then(response => {
    dispatch({
      type: FETCH_PATIENT_LAST_ASSESSMENT,
      payload: {
        lastAssessment: response.data
      }
    });
    return response;
  });

/**
 * Request assessments to fill for specific patient
 * @param {object} patientID
 * @param {string} filters optional, used for filter api
 */
export const fetchPatientTodoAssessments = (patientID, filters = '') => dispatch =>
  WebAppApi.getTodoAssessments(patientID, filters).then(response => {
    dispatch({
      type: FETCH_PATIENT_TODO_ASSESSMENTS,
      payload: {
        todoAssessments: response.data
      }
    });
    return response;
  });
