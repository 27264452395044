import { languageOptions } from 'utils/language';
import {
  ACCEPTED_CLINICAL_TRIAL,
  ADD_TOAST,
  CHANGE_NAVIGATION_PREFERENCES_TODOLIST,
  CHANGE_REGIONAL_PREF,
  FETCH_CLINICALTRIALS_SUCCESS,
  FETCH_LANGUAGE_OPTIONS_SUCCESS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_TIMEZONE_OPTIONS_SUCCESS,
  UPDATE_CLINICALTRIALS_SUCCESS
} from '../constants/actionTypes';
import { SERVER_ACTION_SUCCESS_UPDATE, SERVER_ERROR } from '../constants/messages';
import axios from '../utils/axios';

export const fetchSettings = () => dispatch =>
  axios
    .get('')
    .then(() => {
      // dispatch({
      //   type: FETCH_SETTINGS_SUCCESS,
      //   payload: response.data,
      // })

      const notifications = {
        a: true,
        b: false,
        c: false,
        d: true
      };

      const preferences = {
        language: 'pt',
        timeZone: 'GMT Standard Time'
      };

      dispatch({
        type: FETCH_SETTINGS_SUCCESS,
        payload: {
          notifications,
          preferences
        }
      });
    })
    .catch(() => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
    });

export const fetchLanguageOptions = () => dispatch => {
  dispatch({
    type: FETCH_LANGUAGE_OPTIONS_SUCCESS,
    payload: languageOptions
  });
};

export const timeZoneOptions = [
  { value: 'GMT Standard Time', label: '(UTC) Dublin, Edinburgh, Lisbon, London' },
  { value: 'Paraguay Standard Time', label: '(UTC-04:00) Asuncion' }
];

export const fetchTimeZoneOptions = () => dispatch => {
  dispatch({
    type: FETCH_TIMEZONE_OPTIONS_SUCCESS,
    payload: timeZoneOptions
  });
};

export const changeRegionalPreferences = (URL, language, timezone, showToast = true) => dispatch => {
  // send to server
  const data = {
    regional: {
      language,
      timezone
    }
  };

  return axios
    .patch(URL, data)
    .then(response => {
      // try apply language to i18n
      try {
        window.i18next.changeLanguage(language);
        window.localStorage.setItem('lang', language);
      } catch (e) {
        console.log(e);
      }

      dispatch({
        type: CHANGE_REGIONAL_PREF,
        payload: {
          language,
          timeZone: timezone
        }
      });
      setTimeout(() => {
        // in iiag for example, we want to change the preferences of the eprom to have a certain language but then when all is finished we want to return to the professional main lang and there is no need to show the toast
        if (showToast) {
          dispatch({
            type: ADD_TOAST,
            payload: SERVER_ACTION_SUCCESS_UPDATE
          });
        }
      }, 100);
      return response;
    })
    .catch(response => {
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return response;
    });
};

export const fetchClinicalTrialsBaseAction = payload => ({
  type: FETCH_CLINICALTRIALS_SUCCESS,
  payload
});

export const fetchClinicalTrials = (puid, filters = '') => dispatch =>
  axios
    .get(`/api/terms/patients/${puid}/clinical-trials${filters}`)
    .then(response => {
      dispatch(fetchClinicalTrialsBaseAction(response.data));
      return response;
    })
    .catch(e => {
      console.error(e);
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
      return e.response;
    });

export const updateClinicalTrials = (url, agreed) => dispatch =>
  axios
    .put(url, { agreed })
    .then(response => {
      dispatch({
        type: UPDATE_CLINICALTRIALS_SUCCESS,
        payload: response.data.results
      });
    })
    .catch(e => {
      console.error(e);
      dispatch({
        type: ADD_TOAST,
        payload: SERVER_ERROR
      });
    });

export const acceptedClinicalTrial = ({ ctid, patientId }) => dispatch => {
  dispatch({
    type: ACCEPTED_CLINICAL_TRIAL,
    ctid,
    patientId
  });
};

export const changeNavigationPreferencesTodoList = ({
  instID,
  assessmentType,
  preferenceType,
  preferences
}) => dispatch => {
  dispatch({
    type: CHANGE_NAVIGATION_PREFERENCES_TODOLIST,
    payload: { instID, assessmentType, preferenceType, preferences }
  });
};
