"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var growthbook_react_1 = require("@growthbook/growthbook-react");
var gb = new growthbook_react_1.GrowthBook({
    // @ts-ignore
    apiHost: window.GROWTHBOOK_API_HOST,
    // @ts-ignore
    clientKey: window.GROWTHBOOK_CLIENT_KEY,
    subscribeToChanges: true
});
exports.default = gb;
